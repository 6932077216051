<template>
  <v-dialog
    v-model="dialogAddEditAntecipo"
    @click:outside="$emit('update:dialogAddEditAntecipo', false)"
    @keydown.esc="$emit('update:dialogAddEditAntecipo', false)"
    width="850px"
  >
    <v-card>
      <v-card-title class="subtitle-1 tocs_gray_1">
        {{
          !edit
            ? $tc("global.novo") + " " + $tc("global.antecipo")
            : $tc("global.atualizar") + " " + $tc("global.antecipo")
        }}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogAddEditAntecipo', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="px-6 py-8">
        <v-row>
          <!-- Antecipo é Cliente ou Fornecedor -->
          <v-col cols="12" md="4">
            <v-btn-toggle v-if="!edit" mandatory v-model="tipo_antecipo">
              <v-btn value="cliente" class="text-caption" height="40px">
                {{ $tc("global.cliente") }}
              </v-btn>
              <v-btn value="fornecedor" class="text-caption" height="40px">
                {{ $tc("global.fornecedor") }}
              </v-btn>
            </v-btn-toggle>

            <v-text-field
              v-else
              disabled
              :value="
                tipo_antecipo == 'cliente'
                  ? $tc('global.cliente')
                  : $tc('global.fornecedor')
              "
              :label="`Tipo ${$tc('global.antecipo')}`"
              outlined
              dense
              hide-details
            >
            </v-text-field>
          </v-col>

          <!-- Busca Cliente -->
          <v-col cols="12" md="8" v-if="tipo_antecipo == 'cliente'">
            <SearchClientes v-if="!edit" :cliente_sync.sync="selectedCliente" />

            <v-text-field
              v-else
              disabled
              :value="antecipo.cliente"
              :label="$tc('global.cliente')"
              outlined
              dense
              hide-details
            >
            </v-text-field>
          </v-col>

          <!-- Busca Fornecedor -->
          <v-col cols="12" md="8" v-if="tipo_antecipo == 'fornecedor' && !edit">
            <SearchFornecedores
              v-if="!edit"
              :fornecedor_async.sync="selectedFornecedor"
            />

            <v-text-field
              v-else
              disabled
              :value="antecipo.fornecedor"
              :label="$tc('global.fornecedor')"
              outlined
              dense
              hide-details
            >
            </v-text-field>
          </v-col>

          <!-- Caixas -->
          <v-col cols="12" md="4">
            <v-select
              v-if="!edit"
              v-model="selectedCaixa"
              :items="caixas"
              item-text="descricao"
              item-value="id"
              :loading="loadingCaixas"
              class=""
              outlined
              dense
              hide-details
              :label="$tc('global.caixaDestino')"
              return-object
            ></v-select>

            <v-text-field
              v-else
              disabled
              :value="antecipo.caixa"
              :label="$tc('global.caixa')"
              outlined
              dense
              hide-details
            >
            </v-text-field>
          </v-col>

          <!-- Forma de Pagamento -->
          <v-col cols="12" md="8">
            <v-combobox
              v-if="!edit"
              v-model="selectedFormaPagamento"
              :items="formaPagamentos"
              item-text="descricao"
              item-value="id"
              :loading="loadingFormaPagamentos"
              :label="$tc('global.formapagamento')"
              placeholder="Selecione uma forma de pagamento"
              clearable
              dense
              outlined
              hide-details
            ></v-combobox>

            <v-text-field
              v-else
              disabled
              :value="antecipo.forma_pagamento"
              :label="$tc('global.formapagamento')"
              outlined
              dense
              hide-details
            >
            </v-text-field>
          </v-col>

          <!-- Moedas -->
          <v-col cols="12" md="4">
            <v-combobox
              v-model="selectedMoeda"
              :label="$tc('global.moeda')"
              :items="moedas"
              :disabled="edit"
              dense
              outlined
              required
              hide-details
              item-text="sigla"
              item-value="id"
            ></v-combobox>
          </v-col>

          <!-- Valor -->
          <v-col cols="12" md="8">
            <v-text-field
              :disabled="!selectedMoeda ? true : edit"
              v-model="antecipoValor"
              :label="$tc('global.valor')"
              ref="antecipoValor"
              outlined
              dense
              hide-details
              v-currency="vCurrencyOptions"
            >
            </v-text-field>
          </v-col>

          <!-- Valor Utilizado -->
          <v-col cols="12" v-if="edit">
            <v-text-field
              v-model="antecipoValorUtilizado"
              :label="$tc('global.valorutilizado')"
              ref="antecipoValorUtilizado"
              outlined
              dense
              hide-details
              disabled
              v-currency="vCurrencyOptions"
            >
            </v-text-field>
          </v-col>

          <!-- Documento -->
          <v-col cols="12" md="4">
            <v-text-field
              v-model="antecipo.documento"
              :label="$tc('global.documento')"
              outlined
              dense
              hide-details
            >
            </v-text-field>
          </v-col>

          <!-- Historico -->
          <v-col cols="12" md="8">
            <v-text-field
              v-model="antecipo.historico"
              :label="$tc('global.historico')"
              outlined
              dense
              hide-details
            >
            </v-text-field>
          </v-col>

          <v-col
            v-if="
              selectedFormaPagamento &&
              (selectedFormaPagamento.id === 6 ||
                selectedFormaPagamento.id === 7)
            "
          >
            <v-combobox
              v-model="selectedBandeira"
              class="ma-0"
              height="40px"
              :items="bandeiras"
              item-text="bandeira"
              item-value="id"
              :loading="loading"
              :label="$tc('global.bandeira')"
              clearable
              dense
              outlined
              hide-details
            ></v-combobox>
            <v-text-field
              v-model="codigoAutorizacao"
              :label="$tc('global.codigoAutorizacao')"
              class="pt-4"
              outlined
              dense
              required
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <AntecipoCheque
        v-if="selectedFormaPagamento && selectedFormaPagamento.id === 4"
        :allowCheque.sync="allowCheque"
        :cheque.sync="chequeAdd"
      />

      <v-divider></v-divider>

      <v-card-actions class="px-6 py-4">
        <v-spacer></v-spacer>
        <v-btn
          color="button_2"
          class="white--text"
          depressed
          small
          :disabled="disabledAddEdit"
          :loading="loading"
          @click="edit ? atualizarAntecipo() : criarAntecipo()"
        >
          {{
            !edit
              ? $tc("global.novo") + " " + $tc("global.antecipo")
              : $tc("global.atualizar") + " " + $tc("global.antecipo")
          }}
          <v-icon right>{{ !edit ? "mdi-plus" : "mdi-content-save" }}</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { fetchFormasPagamentos } from "@/api/formas-pagamento/formas_pagamento.js";
import { postAntecipo, putAntecipo } from "@/api/antecipos";
import { fetchCaixasOrigem } from "@/api/caixas/caixas.js";
import { mapState } from "vuex";
import { fetchCartoesBandeiras } from "@/api/cartoes-bandeiras/cartoes_bandeiras.js";

export default {
  name: "DialogAddEditAntecipo",

  components: {
    SearchClientes: () => import("@/components/fields/SearchClientes.vue"),
    SearchFornecedores: () =>
      import("@/components/fields/SearchFornecedores.vue"),
    AntecipoCheque: () => import("./AntecipoCheque.vue"),
  },

  props: {
    dialogAddEditAntecipo: {
      type: Boolean,
      required: true,
    },
    edit: {
      type: Boolean,
      default: true,
    },
    item: {
      type: Object,
    },
  },

  data() {
    return {
      loading: true,
      loadingFormaPagamentos: false,
      loadingCaixas: false,
      antecipo: {
        caixas_id: null,
        users_id: null,
        clientes_id: null,
        fornecedores_id: null,
        formas_pagamentos_id: null,
        moedas_id: null,
        origem: null,
        origem_id: null,
        valor: null,
        valor_utilizado: null,
        documento: null,
        historico: null,
      },
      antecipoValor: null,
      antecipoValorUtilizado: null,
      antecipo_original: {},
      tipo_antecipo: "cliente",
      selectedCaixa: null,
      selectedCliente: null,
      selectedFornecedor: null,
      selectedFormaPagamento: null,
      selectedMoeda: null,
      caixas: null,
      formaPagamentos: [],
      chequeAdd: null,
      allowCheque: false,
      selectedBandeira: null,
      codigoAutorizacao: "",
    };
  },

  computed: {
    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),

    vCurrencyOptions() {
      return {
        precision: this.selectedMoeda?.sigla === "G$" ? 0 : undefined,
        distractionFree: false,
      };
    },

    disabledAddEdit() {
      let result = false;

      if (this.tipo_antecipo == "cliente" && !this.antecipo.clientes_id) {
        result = true;
      }

      if (
        this.tipo_antecipo == "fornecedor" &&
        !this.antecipo.fornecedores_id
      ) {
        result = true;
      }

      if (
        !this.antecipo.formas_pagamentos_id ||
        !this.antecipo.valor ||
        !this.antecipo.caixas_id ||
        !this.antecipo.moedas_id
      ) {
        result = true;
      }

      if (this.edit && this.antecipo.valor_utilizado > this.antecipo.valor) {
        result = true;
      }
      if (this.loading) {
        result = true;
      }
      if (
        this.selectedFormaPagamento &&
        this.selectedFormaPagamento.id == 4 &&
        !this.allowCheque
      ) {
        result = true;
      }

      return result;
    },
  },

  watch: {
    selectedCaixa() {
      if (this.selectedCaixa) {
        this.antecipo.caixas_id = this.selectedCaixa.id;
      }
    },

    selectedCliente() {
      if (this.selectedCliente) {
        this.antecipo.clientes_id = this.selectedCliente.id;
        if (this.selectedCliente.doc_ruc) {
          this.antecipo.documento = this.selectedCliente.doc_ruc;
        } else if (this.selectedCliente.doc_ci) {
          this.antecipo.documento = this.selectedCliente.doc_ci;
        }
      }
    },

    selectedFornecedor() {
      if (this.selectedFornecedor) {
        this.antecipo.fornecedores_id = this.selectedFornecedor.id;
      }
    },

    selectedFormaPagamento() {
      if (this.selectedFormaPagamento) {
        this.antecipo.formas_pagamentos_id = this.selectedFormaPagamento.id;
        if (
          this.selectedFormaPagamento.id === 6 ||
          this.selectedFormaPagamento.id === 7
        ) {
          this.getBandeiras();
          this.moedas.map((moeda) => {
            if (moeda.sigla === "R$" || moeda.sigla === "U$") {
              moeda.disabled = true;
            } else {
              moeda.disabled = false;
            }
          });
          this.selectedMoeda = null;
        } else {
          this.moedas.map((moeda) => {
            moeda.disabled = false;
          });
          this.selectedBandeira = null;
        }
      }
    },

    selectedMoeda() {
      if (this.selectedMoeda) {
        this.antecipo.moedas_id = this.selectedMoeda.id_moeda;
      }
    },

    antecipoValor() {
      if (this.antecipoValor) {
        this.antecipo.valor = this.$ci.parse(
          this.antecipoValor,
          this.vCurrencyOptions
        );
      }
    },

    antecipoValorUtilizado() {
      if (this.antecipoValorUtilizado) {
        this.antecipo.valor_utilizado = this.$ci.parse(
          this.antecipoValorUtilizado,
          this.vCurrencyOptions
        );
      }
    },
    tipo_antecipo() {
      this.selectedFormaPagamento = null;
      this.getFormasDePagamento();
    },

    selectedBandeira() {
      if (this.selectedBandeira) {
        this.antecipo.bandeira_id = this.selectedBandeira.id;
      }
    },
  },

  methods: {
    async getCaixasOrigem() {
      this.loadingCaixas = true;
      const response = await fetchCaixasOrigem();
      this.caixas = response;
      this.loadingCaixas = false;
    },

    async getFormasDePagamento() {
      this.loadingFormaPagamentos = true;
      const response = await fetchFormasPagamentos();
      this.formaPagamentos = response.map((item) => {
        if (
          item.descricao != "DINHEIRO" &&
          item.descricao != "DEPOSITO" &&
          item.descricao != "CHEQUE" &&
          item.descricao != "CARTÃO DE CRÉDITO" &&
          item.descricao != "CARTÃO DE DÉBITO"
        ) {
          item.disabled = true;
        } else if (
          this.tipo_antecipo == "fornecedor" &&
          (item.descricao == "DEPOSITO" || item.descricao == "CHEQUE")
        ) {
          item.disabled = true;
        } else {
          item.disabled = false;
        }

        return item;
      });
      this.loadingFormaPagamentos = false;
    },

    atualizarAntecipo() {
      this.loading = true;

      let antecipo = {
        id: this.antecipo.id,
        valor_utilizado: this.antecipo.valor_utilizado,
        documento: this.antecipo.documento,
        historico: this.antecipo.historico,
      };

      putAntecipo(antecipo.id, antecipo)
        .then((response) => {
          this.$emit("update:dialogAddEditAntecipo", false);
          this.$emit("fetch-antecipos");
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },

    criarAntecipo() {
      this.loading = true;
      if (this.selectedFormaPagamento.id === 4) {
        this.antecipo.cheque = this.chequeAdd;
      }
      if (
        this.selectedFormaPagamento.id === 6 ||
        this.selectedFormaPagamento.id === 7
      ) {
        this.antecipo.codigo_autorizacao = this.codigoAutorizacao;
      }
      postAntecipo(this.antecipo)
        .then((response) => {
          this.$emit("update:dialogAddEditAntecipo", false);
          this.$emit("fetch-antecipos");
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },

    getBandeiras() {
      this.loading = true;
      this.bandeiras = [];
      fetchCartoesBandeiras(
        `?tipo=${this.selectedFormaPagamento.id === 6 ? "CREDITO" : "DEBITO"}`
      )
        .then((response) => {
          this.bandeiras = response;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },

  async mounted() {
    try {
      this.loading = true;
      if (this.edit) {
        this.antecipo = { ...this.item };
        this.antecipo_original = { ...this.item };

        this.selectedMoeda = this.moedas.find(
          (item) => item.id_moeda == this.antecipo.moedas_id
        );

        this.$ci.setValue(this.$refs.antecipoValor, this.antecipo.valor);
        this.$ci.setValue(
          this.$refs.antecipoValorUtilizado,
          this.antecipo.valor_utilizado ? this.antecipo.valor_utilizado : 0
        );
      }
      await this.getFormasDePagamento();
      await this.getCaixasOrigem();
      this.loading = false;
    } catch (error) {
      this.loading = false;
    }
  },
};
</script>

<style></style>
